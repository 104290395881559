const appConfig = {
  devServer: process.env.REACT_APP_INTERNEO_API ?? 'http://localhost:8080',
  quotasServer: 'https://api-quotas.interneo.pro',
  sandboxServer: 'https://api.sandbox.interneo.pro',
  testServer: 'https://api-demo.interneo.pro',
  prodServer: 'https://api.interneo.pro',
  stagingServeur: 'https://api.staging.interneo.pro',
  version: '1.100.0',
  googleTrackingIdDev: 'UA-143245810-1',
  googleTrackingIdProd: 'UA-143245810-2',
  apiPageSize: 30000,
  supportedBrowsers: [ // add other supported browsers
    {
      name: 'Chrome',
      minVersion: 'all' // use a number here if you want to limit a previous version
    },
    {
      name: 'Firefox',
      minVersion: 'all'
    },
    {
      name: 'Edge',
      minVersion: 'all'
    },
    {
      name: 'Opera',
      minVersion: 'all'
    },
    {
      name: 'Brave',
      minVersion: 'all'
    }
  ]

}

export default appConfig
